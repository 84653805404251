<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader breadcrumb="Sales" title="Monthly Metrics" />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <FilterCard>
          <q-form ref="filterForm" @submit="onFilter">
            <div class="row items-start q-col-gutter-md">
              <div class="col-xs-12 col-sm-9 col-md-10">
                <div class="row items-start q-col-gutter-md">
                  <div class="col-xs-12 col-sm-6 col-md-2">
                    <FormDate
                      label="Month From"
                      mask="####/##"
                      v-model="monthFrom"
                      defaultView="YearMonth"
                      :value="monthFrom"
                      :rules="[VALIDATION.required]"
                      @input="onSelectDateFrom"
                      tabindex="1"
                      readonly
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-2">
                    <FormDate
                      label="Month To"
                      mask="####/##"
                      v-model="monthTo"
                      defaultView="YearMonth"
                      :value="monthTo"
                      :rules="[VALIDATION.required]"
                      :options="date => date <= maxDate && date >= minDate"
                      @input="onSelectDateTo"
                      tabindex="2"
                      readonly
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-3">
                    <SelectStores
                      v-model="stores"
                      :values="stores"
                      @updateStore="$event => (stores = $event)"
                      @getOptions="onSetDefaultStores"
                      tabindex="3"
                    />
                  </div>

                  <div class="col-xs-12 col-sm-6 col-md-2">
                    <FormSelect
                      label="Level"
                      v-model="level"
                      :values="level"
                      :options="levelOptions"
                      :rules="[VALIDATION.required]"
                      tabindex="4"
                    />
                  </div>

                  <div
                    class="col-xs-12 col-sm-6 col-md-3"
                    v-if="level === 'category_id'"
                  >
                    <SelectCategories
                      v-model="category"
                      :values="category"
                      @updateStore="$event => (category = $event)"
                      tabindex="5"
                      multiple
                    />
                  </div>

                  <div
                    class="col-xs-12 col-sm-6 col-md-3"
                    v-if="level === 'brand_id'"
                  >
                    <SelectBrands
                      v-model="brand"
                      :values="brand"
                      @updateStore="$event => (brand = $event)"
                      tabindex="6"
                      multiple
                    />
                  </div>

                  <div
                    class="col-xs-12 col-sm-12 col-md-12"
                    v-if="level === 'sku_id'"
                  >
                    <SelectProducts
                      class="max-w-xl mb-2"
                      v-model="sku"
                      :values="sku"
                      :rules="[]"
                      tabindex="7"
                      rawData
                      @updateStore="onSelectSKU"
                    />
                    <Tag
                      variant="default"
                      @remove="onRemoveSKU(tag.value)"
                      v-for="tag in skus"
                      :key="tag?.value"
                      :text="`${tag?.value} - ${tag?.label}`"
                      removable
                      tooltip
                    />
                  </div>
                </div>
              </div>

              <div class="col-xs-12 col-sm-3 col-md-2 text-right">
                <Button
                  variant="primary"
                  label="Filter"
                  icon="o_filter_alt"
                  :loading="loadingSales"
                  :disabled="loadingSales"
                  type="submit"
                  tabindex="8"
                />
              </div>
            </div>
          </q-form>
        </FilterCard>
      </div>

      <Loader :loading="!isFiltered && (loadingSummary || loadingSales)" />

      <div class="row items-start q-col-gutter-md">
        <div
          class="col-xs-12 col-sm-6 col-md-4"
          v-for="list in salesSummary"
          :key="list?.name"
        >
          <DashboardCard
            :variant="list?.variant"
            :title="list?.name"
            :value="list?.value"
            :subValue="list?.subValue"
            :arrow="list?.arrow"
            :loading="loadingSummary"
          />
        </div>
      </div>

      <NoDataCard v-if="noData" />

      <div class="row items-start q-col-gutter-md" v-if="hasData">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Sales Trend">
            <LineChart id="linechart" axis="xy" :data="chartData.sales" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Transaction Count Trend">
            <LineChart
              id="linechart2"
              axis="xy"
              format="number"
              :data="chartData.trxCount"
            />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Basket Size Trend">
            <LineChart id="linechart3" axis="xy" :data="chartData.basketSize" />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Frequency Trend">
            <LineChart
              id="linechart4"
              axis="xy"
              format="number"
              :data="chartData.Frequency"
            />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Unique Customers Trend">
            <LineChart
              id="linechart5"
              axis="xy"
              format="number"
              :data="chartData.uniqueCustomers"
            />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6">
          <SimpleCard header="Unique SKUs Trend">
            <LineChart
              id="linechart6"
              axis="xy"
              format="number"
              :data="chartData.uniqueSKUs"
            />
          </SimpleCard>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12" v-if="false">
          <Table
            :rows="salesTableData"
            :columns="salesTableHeader"
            :loading="loadingTable"
            :limit="limitPage"
            :page="pageNo"
            :total="totalCount"
            @onRequest="onRequest"
          />
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    :footer="false"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <div class="p-4">
      <h5 class="q-mb-md">Monthly Metrics</h5>
      <p>
        {{ HELP.MONTHLY_METRICS.description }}
      </p>

      <q-separator class="q-my-md" />

      <h6 class="q-mb-md text-sm">Definition of Terms</h6>
      <Table
        style="max-height: 50vh"
        :rows="HELP.MONTHLY_METRICS.terms"
        :columns="HELP.TABLE"
        :limit="0"
        hide-bottom
      >
        <template v-slot:body="props">
          <q-tr>
            <q-td>
              <strong>{{ props.row.name }}</strong>
            </q-td>
            <q-td :style="{ whiteSpace: 'normal' }">
              {{ props.row.description }}
            </q-td>
          </q-tr>
        </template>
      </Table>
    </div>
  </Modal>
</template>

<script>
import { ref, inject, computed, watch, onBeforeMount } from 'vue'
import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import {
  FilterCard,
  NoDataCard,
  DashboardCard,
  SimpleCard
} from '@/components/cards'
import { FormSelect, FormDate } from '@/components/inputs'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import Loader from '@/components/Loader'
import Tag from '@/components/Tag'
import {
  SelectStores,
  SelectCategories,
  SelectBrands,
  SelectProducts
} from '@/components/customs'
import { LineChart } from '@/components/charts'

import { HELP, optionsLevelID } from '@/constants'

import {
  DATE,
  FORMAT,
  VALIDATION,
  EXPORT,
  Toast,
  getIconUrl,
  fetchData
} from '@/tools'

export default {
  name: 'MonthlyMetrics',
  components: {
    PageHeader,
    PageContent,
    FilterCard,
    NoDataCard,
    DashboardCard,
    SimpleCard,
    FormSelect,
    FormDate,
    Button,
    Table,
    Modal,
    Loader,
    Tag,
    SelectStores,
    SelectCategories,
    SelectBrands,
    SelectProducts,
    LineChart
  },
  setup() {
    const { showToast } = Toast()
    const state = inject('store')?.state

    const {
      data: dataSummary,
      error: errorSummary,
      loading: loadingSummary,
      post: postSummary
    } = fetchData()

    const {
      data: dataSales,
      error: errorSales,
      loading: loadingSales,
      post: postSales
    } = fetchData()

    const isRetailer = ref(state?.accountType?.retailer)
    const showModal = ref(false)
    const loadingTable = ref(false)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)
    const filterForm = ref(null)
    const monthFrom = ref(
      DATE.toFriendlyDate(
        DATE.toFriendlyFirstDay(DATE.subtractDays(new Date(), 1, 'months'))
      )
    )
    const monthTo = ref(
      DATE.toFriendlyDate(
        DATE.toFriendlyFirstDay(DATE.subtractDays(new Date(), 1, 'months'))
      )
    )
    const stores = ref([])
    const category = ref(null)
    const brand = ref(null)
    const sku = ref(null)
    const skus = ref([])
    const level = ref(optionsLevelID[0].value)
    const levelOptions = ref(optionsLevelID)
    const salesSummary = ref(null)
    const chartData = ref({
      sales: [],
      trxCount: [],
      basketSize: [],
      Frequency: [],
      uniqueCustomers: [],
      uniqueSKUs: []
    })
    const salesTableData = ref(null)
    const salesTableHeader = ref([
      {
        name: 'businessMonth',
        label: 'Business Month',
        field: 'businessMonth',
        align: 'center',
        sortable: true
      },
      {
        name: 'sales',
        label: 'Sales',
        field: 'sales',
        format: val => FORMAT.toCurrency(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'trxCount',
        label: 'Trans Count',
        field: 'trxCount',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'basketSizeAmt',
        label: 'Basket Size Amount',
        field: 'basketSizeAmt',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'frequency',
        label: 'Frequency',
        field: 'frequency',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'uniqueCustomers',
        label: 'Unique Customers',
        field: 'uniqueCustomers',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'uniqueSKU',
        label: 'Unique SKUs',
        field: 'uniqueSKU',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      }
    ])

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )

    const noData = computed(
      () => salesTableData.value && salesTableData.value.length === 0
    )

    const maxDate = computed(() => {
      return DATE.toFriendlyDate(new Date(), '/')
    })

    const minDate = computed(() => {
      return DATE.toFriendlyDate(monthFrom.value, '/')
    })

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onFilter = () => {
      if (!isFiltered.value) {
        limitPage.value = 10
        pageNo.value = 1
        totalCount.value = 0
      }

      filterForm.value.validate().then(success => {
        if (level.value === 'sku_id' && skus.value.length === 0) {
          showToast('Please select at least 1 SKU', 'info')
        } else {
          if (success) {
            const payload = {
              pagination: {
                page: pageNo.value,
                per_page: limitPage.value
              },
              filters: {
                month_from: DATE.toFriendlyDate(monthFrom.value),
                month_to: DATE.toFriendlyDate(monthTo.value),
                stores: stores.value,
                level: level.value
              }
            }

            if (level.value === 'category_id') {
              payload.filters.values = category.value
            }

            if (level.value === 'brand_id') {
              payload.filters.values = brand.value
            }

            if (level.value === 'sku_id') {
              payload.filters.values = skus.value.map(item => item.value)
            }

            if (isRetailer.value) {
              payload.filters.principal = state?.defaultID

              postSummary('/v1/retailer/sales/monthly-trend/summary', payload)
              postSales('/v1/retailer/sales/monthly-trend/detailed', payload)
            } else {
              payload.filters.retailer = state?.defaultID

              postSummary('/v1/sales/monthly-trend/summary', payload)
              postSales('/v1/sales/monthly-trend/detailed', payload)
            }
          } else {
            showToast('Please fill all the required fields', 'info')
          }
        }
      })
    }

    const exportTable = () => {
      let linechartImg,
        linechartImg2,
        linechartImg3,
        linechartImg4,
        linechartImg5,
        linechartImg6

      FORMAT.svg2img('linechart', 600, 350, function (pngData) {
        linechartImg = pngData
      })

      FORMAT.svg2img('linechart2', 600, 350, function (pngData) {
        linechartImg2 = pngData
      })

      FORMAT.svg2img('linechart3', 600, 350, function (pngData) {
        linechartImg3 = pngData
      })

      FORMAT.svg2img('linechart4', 600, 350, function (pngData) {
        linechartImg4 = pngData
      })

      FORMAT.svg2img('linechart5', 600, 350, function (pngData) {
        linechartImg5 = pngData
      })

      FORMAT.svg2img('linechart6', 600, 350, function (pngData) {
        linechartImg6 = pngData
      })

      setTimeout(() => {
        if (
          linechartImg &&
          linechartImg2 &&
          linechartImg3 &&
          linechartImg4 &&
          linechartImg5 &&
          linechartImg6
        ) {
          EXPORT.exportMonthlyMetricsToExcel({
            header: salesTableHeader.value,
            data: salesTableData.value,
            filename: 'monthly_metrics',
            chart: linechartImg,
            chart2: linechartImg2,
            chart3: linechartImg3,
            chart4: linechartImg4,
            chart5: linechartImg5,
            chart6: linechartImg6,
            position: {
              table: 64
            }
          })
        }
      }, 500)
    }

    const onRequest = props => {
      const { page, rowsPerPage } = props.pagination
      isFiltered.value = true
      pageNo.value = page
      limitPage.value = rowsPerPage

      onFilter()
    }

    const onRemoveSKU = data => {
      skus.value = skus.value.filter(item => item.value !== data)
    }

    const onSelectSKU = data => {
      if (data) {
        const exists = Object.values(skus.value).includes(data)

        if (!exists && data && data.length !== 0) {
          skus.value = [...skus.value, data]
        }
      }
      sku.value = null
    }

    const onSetDefaultStores = data => {
      stores.value = data.map(item => item.value)
    }

    const onSelectDateFrom = data => {
      monthFrom.value = data
      monthTo.value = DATE.toFriendlyDate(
        DATE.toFriendlyFirstDay(DATE.subtractDays(new Date(), 1, 'months'))
      )
    }

    const onSelectDateTo = data => {
      monthTo.value = data
    }

    onBeforeMount(() => {
      watch([dataSummary, errorSummary, loadingSummary], () => {
        if (loadingSummary.value) {
          salesSummary.value = null
        } else {
          if (errorSummary?.value) {
            showToast(
              errorSummary?.value?.message ??
                'There was a problem fetching summary.',
              'danger'
            )
          } else if (dataSummary?.value) {
            const data = dataSummary?.value

            salesSummary.value = [
              {
                name: 'Sales',
                value: FORMAT.toCurrency(data?.sales_cur ?? 0),
                subValue: `YA: ${FORMAT.toCurrency(data?.sales_ya ?? 0)}`,
                arrow: data?.sales_cur > data?.sales_ya ? 'up' : 'down',
                variant: 'primary'
              },
              {
                name: 'Trans Count',
                value: FORMAT.toNumber(data?.trx_count_cur ?? 0),
                subValue: `YA: ${FORMAT.toNumber(data?.trx_count_ya ?? 0)}`,
                arrow: data?.trx_count_cur > data?.trx_count_ya ? 'up' : 'down',
                variant: 'primary'
              },
              {
                name: 'Basket Size Amount',
                value: FORMAT.toNumber(data?.basket_size_amount_cur ?? 0),
                subValue: `YA: ${FORMAT.toNumber(
                  data?.basket_size_amount_ya ?? 0
                )}`,
                arrow:
                  data?.basket_size_amount_cur > data?.basket_size_amount_ya
                    ? 'up'
                    : 'down',
                variant: 'primary'
              },
              {
                name: 'Frequency',
                value: FORMAT.toNumber(data?.frequency_cur ?? 0),
                subValue: `YA: ${FORMAT.toNumber(data?.frequency_ya ?? 0)}`,
                arrow: data?.frequency_cur > data?.frequency_ya ? 'up' : 'down',
                variant: 'secondary'
              },
              {
                name: 'Unique Customers',
                value: FORMAT.toNumber(data?.unique_customers_cur ?? 0),
                subValue: `YA: ${FORMAT.toNumber(
                  data?.unique_customers_ya ?? 0
                )}`,
                arrow:
                  data?.unique_customers_cur > data?.unique_customers_ya
                    ? 'up'
                    : 'down',
                variant: 'secondary'
              },
              {
                name: 'Unique SKUs',
                value: FORMAT.toNumber(data?.unique_sku_cur ?? 0),
                subValue: `YA: ${FORMAT.toNumber(data?.unique_sku_ya ?? 0)}`,
                arrow:
                  data?.unique_sku_cur > data?.unique_sku_ya ? 'up' : 'down',
                variant: 'secondary'
              }
            ]
          }
        }
      })

      watch([dataSales, errorSales, loadingSales], () => {
        if (loadingSales.value) {
          if (isFiltered.value) {
            loadingTable.value = true
          } else {
            salesTableData.value = null
          }
        } else {
          if (errorSales?.value) {
            showToast(
              errorSales?.value?.message ??
                'There was a problem fetching sales.',
              'danger'
            )
          } else if (dataSales?.value) {
            const data = dataSales?.value
            totalCount.value = data?.length

            salesTableData.value = data?.map(item => ({
              businessMonth: DATE.toFriendlyMonthYearName(item?.month_year),
              sales: item?.sales ?? 0,
              trxCount: item?.trx_count ?? 0,
              basketSizeAmt: item?.basket_size_amount ?? 0,
              frequency: item?.frequency ?? 0,
              uniqueCustomers: item?.unique_customers ?? 0,
              uniqueSKU: item?.unique_sku ?? 0
            }))

            chartData.value = {
              sales: data?.map(item => ({
                name: DATE.toFriendlyMonthYearName(item?.month_year),
                value: FORMAT.toFixedNumber(item?.sales ?? 0)
              })),
              trxCount: data?.map(item => ({
                name: DATE.toFriendlyMonthYearName(item?.month_year),
                value: item?.trx_count ?? 0
              })),
              basketSize: data?.map(item => ({
                name: DATE.toFriendlyMonthYearName(item?.month_year),
                value: FORMAT.toFixedNumber(item?.basket_size_amount ?? 0)
              })),
              Frequency: data?.map(item => ({
                name: DATE.toFriendlyMonthYearName(item?.month_year),
                value: FORMAT.toFixedNumber(item?.frequency ?? 0)
              })),
              uniqueCustomers: data?.map(item => ({
                name: DATE.toFriendlyMonthYearName(item?.month_year),
                value: item?.unique_customers ?? 0
              })),
              uniqueSKUs: data?.map(item => ({
                name: DATE.toFriendlyMonthYearName(item?.month_year),
                value: item?.unique_sku ?? 0
              }))
            }
          }

          isFiltered.value = false
          loadingTable.value = false
        }
      })
    })

    return {
      HELP,
      showModal,
      loadingSummary,
      loadingSales,
      loadingTable,
      isFiltered,
      limitPage,
      pageNo,
      totalCount,
      filterForm,
      monthFrom,
      monthTo,
      stores,
      category,
      brand,
      sku,
      skus,
      level,
      levelOptions,
      salesSummary,
      chartData,
      salesTableHeader,
      salesTableData,
      FORMAT,
      VALIDATION,
      getIconUrl,
      hasData,
      noData,
      maxDate,
      minDate,
      onToggleModal,
      onFilter,
      exportTable,
      onRequest,
      onRemoveSKU,
      onSelectSKU,
      onSetDefaultStores,
      onSelectDateFrom,
      onSelectDateTo
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.q-field--outlined.q-field--readonly .q-field__control:before) {
  border-style: solid;
}

:deep(.q-date__arrow) {
  @apply hidden;
}
</style>
