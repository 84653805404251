<template>
  <q-card class="text-white font-bold px-1" :class="bgClass">
    <q-card-section>
      <div class="cardTitle" v-if="title">
        <div class="row justify-between">
          {{ title }}
          <q-icon
            :name="arrow === 'up' ? 'arrow_circle_up' : 'arrow_circle_down'"
            color="white"
            size="sm"
            v-if="arrow"
          />
        </div>
      </div>

      <div class="cardValue" v-if="value">
        {{ value }}
      </div>

      <div class="cardSubValue" v-if="subValue">
        {{ subValue }}
      </div>

      <div class="cardSubTitle" v-if="subTitle">
        {{ subTitle }}
      </div>

      <slot />
    </q-card-section>
    <Loader :loading="loading" inner />
  </q-card>
</template>

<script>
import Loader from '@/components/Loader'

export default {
  components: { Loader },
  props: {
    variant: {
      type: String,
      default: '',
      validator: val => {
        if (val) {
          return [
            'default',
            'primary',
            'secondary',
            'success',
            'warning',
            'info',
            'danger'
          ].includes(val)
        }
        return true
      }
    },
    title: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    subValue: {
      type: [String, Number],
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    arrow: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const bgClass = {
      'text-black': !props.variant,
      'bg-gray text-black': props.variant === 'default',
      'bg-primary': props.variant === 'primary',
      'bg-secondary': props.variant === 'secondary',
      'bg-success': props.variant === 'success',
      'bg-warning': props.variant === 'warning',
      'bg-info': props.variant === 'info',
      'bg-danger': props.variant === 'danger'
    }

    return { bgClass }
  }
}
</script>

<style lang="scss" scoped>
.cardTitle {
  @apply mb-2;
  @apply text-sm;
}

.cardValue {
  @apply mb-2;
  @apply text-3xl;
}

.cardSubValue {
  @apply text-sm font-normal;
}

.cardSubTitle {
  @apply text-sm font-normal;
}
</style>
