<template>
  <div>
    <SimpleCard>
      <div class="q-pa-xl column items-center justify-center text-primary">
        <q-icon name="description" size="xl" />
        <span class="text-h7">No Data</span>
      </div>
    </SimpleCard>
  </div>
</template>

<script>
import { SimpleCard } from '@/components/cards'

export default {
  components: {
    SimpleCard
  }
}
</script>
