<template>
  <MonthlyMetrics />
</template>

<script>
import MonthlyMetrics from '@/components/pages/sales/MonthlyMetrics'

export default {
  components: {
    MonthlyMetrics
  }
}
</script>
