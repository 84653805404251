<template>
  <div ref="resizeRef" class="pt-8 overflow-hidden">
    <div class="legendContainer">
      <div
        class="legendItem"
        :class="!legend1 ? 'text-gray' : ''"
        @click="onToggleLegend"
      >
        <div
          class="legendBox primary"
          :class="!legend1 ? 'bg-gray' : 'bg-primary'"
        />
        Selected Year
      </div>
      <div
        class="legendItem"
        :class="!legend2 ? 'text-gray' : ''"
        @click="onToggleLegend2"
      >
        <div
          class="legendBox success"
          :class="!legend2 ? 'bg-gray' : 'bg-success'"
        />
        Year Ago
      </div>
    </div>
    <svg ref="svgRef" :id="id">
      <g>
        <g class="x-axis" v-if="axis === 'x' || axis === 'xy'" />
        <g class="y-axis" v-if="axis === 'y' || axis === 'xy'" />
      </g>
    </svg>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from 'vue'
import {
  select,
  line,
  scaleLinear,
  scalePoint,
  max,
  min,
  curveCatmullRom,
  axisBottom,
  axisLeft,
  easeLinear
} from 'd3'
import { tip } from 'd3-v6-tip'
import useResizeObserver from '@/tools/resizeObserver'

import { FORMAT } from '@/tools'

export default {
  name: 'TrendLineChart',
  props: ['id', 'data', 'axis', 'xLabel', 'yLabel', 'xData'],
  setup(props) {
    const { resizeRef, resizeState } = useResizeObserver()
    const svgRef = ref(null)
    const legend1 = ref(true)
    const legend2 = ref(true)

    const onToggleLegend = () => {
      legend1.value = !legend1.value
    }

    const onToggleLegend2 = () => {
      legend2.value = !legend2.value
    }

    onMounted(() => {
      watchEffect(() => {
        let { width, height } = resizeState.dimensions
        let maxw = 0
        let maxh = 0
        const margin = { top: 20, right: 20, bottom: 40, left: 40 }

        width = width - margin.left - margin.right
        height = 350 - margin.top - margin.bottom

        const svg = select(svgRef.value)
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
          .attr(
            'transform',
            'translate(' + margin.left + ',' + margin.top + ')'
          )

        svg
          .select('.y-axis')
          .selectAll('text')
          .each(function () {
            if (this.getBBox().width > maxw) maxw = this.getBBox().width
          })

        svg
          .select('.x-axis')
          .selectAll('text')
          .each(function () {
            if (this.getBBox().width > maxh) maxh = this.getBBox().width
          })

        const minData = min(props.data, v => v.value)
        const minData2 = min(props.data, v => v.value2)
        const maxData = max(props.data, v => v.value)
        const maxData2 = max(props.data, v => v.value2)

        const getMaxData = () => {
          if (maxData < maxData2) {
            return maxData2
          } else {
            return maxData
          }
        }

        const getMinData = () => {
          if (minData < minData2) {
            return minData
          } else {
            return minData2
          }
        }

        const xScale = scalePoint()
          .domain(['', ...props.data.map(v => v.name), ' '])
          .range([0, width - maxw])

        const yScale = scaleLinear()
          .domain([
            getMinData() - getMinData() * 0.1,
            getMaxData() + getMaxData() * 0.1
          ])
          .range([height, 0])

        const xAxis = axisBottom(xScale)
        const yAxis = axisLeft(yScale).ticks(5)

        // Tooltip
        const tooltip = tip()
          .attr('class', 'd3-tip')
          .offset([-10, 0])
          .html((e, v, type) => {
            if (type === 'graph2') {
              return FORMAT.toCurrency(v.value2)
            } else {
              return FORMAT.toCurrency(v.value)
            }
          })

        svg.call(tooltip)

        // Refresh Graph
        svg.selectAll('circle').remove()
        svg.selectAll('.line-chart').remove()
        svg.selectAll('.x-label').remove()
        svg.selectAll('.y-label').remove()

        // ------------------------------

        if (legend1.value) {
          const lineGraph = line()
            .curve(curveCatmullRom)
            .x(v => xScale(v.name))
            .y(v => yScale(v.value))

          const lineData = svg
            .append('g')
            .selectAll('.line-chart')
            .data([props.data])
            .join('path')
            .attr('class', 'line-chart primary')
            .style('fill', 'none')
            .attr('d', lineGraph)

          // Animate points on page load
          const totalLength = lineData.node().getTotalLength()
          const transitionPath = lineData
            .transition()
            .ease(easeLinear)
            .duration(1000)

          // set animation
          lineData
            .attr('stroke-dashoffset', totalLength)
            .attr('stroke-dasharray', totalLength)
            .transition(transitionPath)
            .attr('stroke-dashoffset', 0)

          // Points Graph
          const pointsGraph = svg
            .append('g')
            .selectAll('.line-chart-dot')
            .data(props.data)
            .enter()
            .append('circle')
            .attr('class', 'line-chart-dot primary')
            .attr('cx', lineGraph.x())
            .attr('cy', lineGraph.y())
            .attr('r', 2)

          // Animate points on page load
          pointsGraph
            .style('opacity', 0)
            .transition()
            .duration(250)
            .ease(easeLinear)
            .delay(1000)
            .style('opacity', 1)

          // Add user interaction
          pointsGraph
            .on('mouseover', function (e, v) {
              tooltip.offset([-10, 0]).direction('n').show(e, v)

              // Animate circle radius
              select(this).transition().duration(250).attr('r', 3)
            })
            .on('mouseout', function () {
              tooltip.hide()
              // Animate circle radius
              select(this).transition().duration(250).attr('r', 2)
            })

          // Change tooltip direction of first point
          select(pointsGraph._groups[0][0])
            .on('mouseover', function (e, v) {
              tooltip.offset([0, 10]).direction('e').show(e, v)

              // Animate circle radius
              select(this).transition().duration(250).attr('r', 3)
            })
            .on('mouseout', function () {
              tooltip.hide()

              // Animate circle radius
              select(this).transition().duration(250).attr('r', 2)
            })

          // Change tooltip direction of last point
          select(pointsGraph._groups[0][pointsGraph.size() - 1])
            .on('mouseover', function (e, v) {
              tooltip.offset([0, -10]).direction('w').show(e, v)

              // Animate circle radius
              select(this).transition().duration(250).attr('r', 3)
            })
            .on('mouseout', function () {
              tooltip.hide()

              // Animate circle radius
              select(this).transition().duration(250).attr('r', 2)
            })
        }

        if (legend2.value) {
          const lineGraph2 = line()
            .curve(curveCatmullRom)
            .x(v => xScale(v.name))
            .y(v => yScale(v.value2))

          const lineData2 = svg
            .append('g')
            .selectAll('.line-chart')
            .data([props.data])
            .join('path')
            .attr('class', 'line-chart success')
            .style('fill', 'none')
            .attr('d', lineGraph2)

          // Animate points on page load
          const totalLength2 = lineData2.node().getTotalLength()
          const transitionPath2 = lineData2
            .transition()
            .ease(easeLinear)
            .duration(1000)

          // set animation
          lineData2
            .attr('stroke-dashoffset', totalLength2)
            .attr('stroke-dasharray', totalLength2)
            .transition(transitionPath2)
            .attr('stroke-dashoffset', 0)

          // Points Graph
          const pointsGraph2 = svg
            .append('g')
            .selectAll('.line-chart-dot')
            .data(props.data)
            .enter()
            .append('circle')
            .attr('class', 'line-chart-dot success')
            .attr('cx', lineGraph2.x())
            .attr('cy', lineGraph2.y())
            .attr('r', 2)

          // Animate points on page load
          pointsGraph2
            .style('opacity', 0)
            .transition()
            .duration(250)
            .ease(easeLinear)
            .delay(1000)
            .style('opacity', 1)

          // Add user interaction
          pointsGraph2
            .on('mouseover', function (e, v) {
              tooltip.offset([-10, 0]).direction('n').show(e, v, 'graph2')

              // Animate circle radius
              select(this).transition().duration(250).attr('r', 3)
            })
            .on('mouseout', function () {
              tooltip.hide()
              // Animate circle radius
              select(this).transition().duration(250).attr('r', 2)
            })

          // Change tooltip direction of first point
          select(pointsGraph2._groups[0][0])
            .on('mouseover', function (e, v) {
              tooltip.offset([0, 10]).direction('e').show(e, v, 'graph2')

              // Animate circle radius
              select(this).transition().duration(250).attr('r', 3)
            })
            .on('mouseout', function () {
              tooltip.hide()

              // Animate circle radius
              select(this).transition().duration(250).attr('r', 2)
            })

          // Change tooltip direction of last point
          select(pointsGraph2._groups[0][pointsGraph2.size() - 1])
            .on('mouseover', function (e, v) {
              tooltip.offset([0, -10]).direction('w').show(e, v, 'graph2')

              // Animate circle radius
              select(this).transition().duration(250).attr('r', 3)
            })
            .on('mouseout', function () {
              tooltip.hide()

              // Animate circle radius
              select(this).transition().duration(250).attr('r', 2)
            })
        }

        // set axis
        svg
          .select('.x-axis')
          .attr('class', 'x-axis')
          .attr('transform', 'translate(0,' + height + ')')
          .call(xAxis.tickSize(-height))
          .selectAll('text')
          .style('text-anchor', 'start')
          .attr('dx', '10px')
          .attr('dy', '-2px')
          .attr('transform', 'rotate(65)')

        svg
          .select('.x-axis')
          .append('text')
          .attr('class', 'x-label')
          .attr('x', (width - margin.left) / 2)
          .attr('y', maxh + 10)
          .text(props.xLabel)

        svg
          .select('.y-axis')
          .call(yAxis.tickSize(-(width - maxw)))
          .attr('class', 'y-axis')
          .append('text')
          .attr('class', 'y-label')
          .attr('transform', 'rotate(-90)')
          .attr('x', -(height - margin.top) / 2)
          .attr('y', -maxw - 10)
          .attr('dy', -16)
          .style('text-anchor', 'end')
          .text(props.yLabel)

        svg
          .attr('transform', 'translate(' + (maxw + margin.left) + ',0)')
          .attr('height', height + margin.bottom + maxh)
      })
    })

    return {
      svgRef,
      resizeRef,
      legend1,
      legend2,
      onToggleLegend,
      onToggleLegend2
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(svg) {
  display: block;
  fill: none;
  stroke: none;
  width: 100%;
  height: 100%;
  overflow: visible !important;

  .line-chart,
  .line-chart-dot {
    stroke-width: 1.5px;
    stroke: black;

    &.primary {
      fill: $primary;
      stroke: $primary;
    }

    &.warning {
      fill: $warning;
      stroke: $warning;
    }

    &.success {
      fill: $success;
      stroke: $success;
    }

    &.info {
      fill: $info;
      stroke: $info;
    }
  }

  .y-axis,
  .x-axis {
    line,
    path {
      color: $grey-6;
    }

    line {
      stroke: lightgrey;
      opacity: 0.7;
    }
  }

  .y-label,
  .x-label {
    @apply text-sm font-semibold;
    fill: black;
  }
}

.legendContainer {
  @apply mb-4;
  @apply flex items-center justify-center gap-8;

  .legendItem {
    @apply flex items-center justify-center gap-2;
    @apply cursor-pointer;

    &:hover {
      @apply font-semibold text-black;

      .legendBox {
        &.primary {
          @apply bg-primary;
        }

        &.success {
          @apply bg-success;
        }
      }
    }

    .legendBox {
      @apply w-2 h-2;
    }
  }
}
</style>
