<template>
  <q-list bordered class="bg-white rounded-borders shadow-1">
    <q-card flat v-if="noHeader">
      <q-card-section>
        <slot />
      </q-card-section>
    </q-card>
    <q-expansion-item
      expand-icon-class="text-primary"
      expand-icon-toggle
      expand-separator
      default-opened
      v-else
    >
      <template v-slot:header>
        <div class="title">
          {{ title }}
        </div>
        <q-space />
      </template>

      <q-separator />
      <q-card flat>
        <q-card-section>
          <slot />
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </q-list>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Filters'
    },
    noHeader: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  @apply mt-1;
  @apply text-base font-semibold;
}
</style>
