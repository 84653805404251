<template>
  <div ref="resizeRef" class="pt-8 overflow-hidden">
    <svg ref="svgRef" :id="id">
      <g>
        <g class="x-axis" v-if="axis === 'x' || axis === 'xy'" />
        <g class="y-axis" v-if="axis === 'y' || axis === 'xy'" />
      </g>
    </svg>
  </div>
</template>

<script>
import { ref, computed, watchEffect, onMounted } from 'vue'
import {
  select,
  scaleBand,
  scaleLinear,
  axisBottom,
  axisLeft,
  max,
  easeCubic
} from 'd3'
import { tip } from 'd3-v6-tip'
import useResizeObserver from '@/tools/resizeObserver'

import { FORMAT } from '@/tools'

export default {
  name: 'HorizontalBarChart',
  props: ['id', 'type', 'data', 'limit', 'axis', 'xLabel', 'yLabel', 'xData'],
  setup(props) {
    const svgRef = ref(null)
    const { resizeRef, resizeState } = useResizeObserver()

    const graphData = computed(() => {
      if (props.limit) {
        return props.data.slice(0, props.limit)
      } else {
        return props.data
      }
    })

    onMounted(() => {
      watchEffect(() => {
        let { width, height } = resizeState.dimensions
        let maxw = 0
        let maxh = 0
        const margin = { top: 20, right: 20, bottom: 40, left: 40 }

        width = width - margin.left - margin.right
        height = 350 - margin.top - margin.bottom

        const svg = select(svgRef.value)
          .style('background-color', 'white')
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
          .attr(
            'transform',
            'translate(' + margin.left + ',' + margin.top + ')'
          )

        svg
          .select('.y-axis')
          .selectAll('text')
          .each(function () {
            if (this.getBBox().width > maxw) maxw = this.getBBox().width
          })

        svg
          .select('.x-axis')
          .selectAll('text')
          .each(function () {
            if (this.getBBox().width > maxh) maxh = this.getBBox().width
          })

        const yScale = scaleBand()
          .domain(graphData.value.map(data => data.name))
          .rangeRound([0, height])
          .padding(0.1)

        const xScale = scaleLinear()
          .domain([
            0,
            max(graphData.value, v => {
              return Math.max(v.value)
            })
          ])
          .rangeRound([0, width - maxw])

        const xAxis = axisBottom(xScale)
        const yAxis = axisLeft(yScale)

        const tooltip = tip()
          .attr('class', 'd3-tip')
          .offset([-10, 0])
          .html((e, v) => {
            return FORMAT.toCurrency(v.value)
          })

        svg.call(tooltip)

        // Refresh Graph
        svg.selectAll('rect').remove()
        svg.selectAll('.x-label').remove()
        svg.selectAll('.y-label').remove()

        const barGraph = svg
          .select('g')
          .append('g')
          .selectAll('.bar')
          .data(graphData.value)
          .enter()
          .append('rect')
          .classed('bar', true)
          .attr('fill', '#2e4faa')
          .attr('width', () => xScale(0))
          .attr('height', yScale.bandwidth())
          .attr('x', () => xScale(0))
          .attr('y', v => yScale(v.name))
          .on('mouseover', tooltip.show)
          .on('mouseout', tooltip.hide)

        // set animation
        barGraph
          .transition()
          .ease(easeCubic)
          .duration(800)
          .attr('width', v => xScale(v.value))
          .delay((v, i) => i * 5)

        // set axis
        svg
          .select('.x-axis')
          .attr('class', 'x-axis')
          .attr('transform', 'translate(0,' + height + ')')
          .call(xAxis.tickSize(-height))
          .selectAll('text')
          .style('text-anchor', 'start')
          .attr('dx', '10px')
          .attr('dy', '-2px')
          .attr('transform', 'rotate(65)')

        svg
          .select('.x-axis')
          .append('text')
          .attr('class', 'x-label')
          .attr('x', (width - margin.left) / 2)
          .attr('y', maxh + 10)
          .text(props.xLabel)

        svg
          .select('.y-axis')
          .call(yAxis)
          .attr('class', 'y-axis')
          .append('text')
          .attr('class', 'y-label')
          .attr('transform', 'rotate(-90)')
          .attr('x', -(height - margin.top) / 2)
          .attr('y', -maxw - 10)
          .attr('dy', -16)
          .style('text-anchor', 'end')
          .text(props.yLabel)

        svg
          .attr('height', height + margin.bottom + maxh)
          .attr(
            'viewBox',
            `-${maxw + margin.left} 0 ${
              width + margin.left + margin.right + margin.top + margin.bottom
            } ${
              height + margin.top + margin.bottom + margin.left + margin.right
            }`
          )
      })
    })

    return { svgRef, resizeRef }
  }
}
</script>

<style lang="scss" scoped>
:deep(svg) {
  display: block;
  fill: none;
  stroke: none;
  width: 100%;
  height: 100%;
  overflow: visible !important;

  .bar {
    fill: $primary;

    &.primary {
      fill: $primary;
    }

    &.warning {
      fill: $warning;
    }

    &.success {
      fill: $success;
    }

    &.info {
      fill: $info;
    }
  }

  .y-axis,
  .x-axis {
    line,
    path {
      color: $grey-6;
    }

    line {
      stroke: lightgrey;
      opacity: 0.7;
    }
  }

  .y-label,
  .x-label {
    @apply text-sm font-semibold;
    fill: black;
  }
}
</style>
