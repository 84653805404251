<template>
  <div class="pt-8 w-80 h-32">
    <svg ref="svgRef" :id="id" />
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from 'vue'
import {
  select,
  line,
  scaleLinear,
  scalePoint,
  max,
  curveCardinal,
  easeLinear
} from 'd3'
import { tip } from 'd3-v6-tip'

import { FORMAT } from '@/tools'

export default {
  name: 'LineChart',
  props: ['id', 'data', 'axis', 'xLabel', 'yLabel', 'xData'],
  setup(props) {
    const svgRef = ref(null)

    onMounted(() => {
      watchEffect(() => {
        const margin = { top: 20, right: 20, bottom: 20, left: 20 }

        const width = 250
        const height = 50

        const svg = select(svgRef.value)
          .attr('width', width)
          .attr('height', height)
          .attr(
            'transform',
            'translate(' + margin.left + ',' + margin.top + ')'
          )

        const xScale = scalePoint()
          .domain([...props.data.map(v => v.name)])
          .range([0, width])

        const yScale = scaleLinear()
          .domain([
            0,
            max(props.data, v => {
              return Math.max(v.value)
            })
          ])
          .range([height, 0])

        // Tooltip
        const tooltip = tip()
          .attr('class', 'd3-tip')
          .offset([-10, 0])
          .html((e, v) => {
            return FORMAT.toCurrency(v.value)
          })

        svg.call(tooltip)

        // Refresh Graph
        svg.selectAll('circle').remove()
        svg.selectAll('.line-chart').remove()

        // ------------------------------

        const lineGraph = line()
          .curve(curveCardinal)
          .x(v => xScale(v.name))
          .y(v => yScale(v.value))

        const lineData = svg
          .append('g')
          .selectAll('.line-chart')
          .data([props.data])
          .join('path')
          .attr('class', 'line-chart primary')
          .style('fill', 'none')
          .attr('d', lineGraph)

        // Animate points on page load
        const totalLength = lineData.node().getTotalLength()
        const transitionPath = lineData
          .transition()
          .ease(easeLinear)
          .duration(1000)

        lineData
          .attr('stroke-dashoffset', totalLength)
          .attr('stroke-dasharray', totalLength)
          .transition(transitionPath)
          .attr('stroke-dashoffset', 0)

        // Points Graph
        const pointsGraph = svg
          .append('g')
          .selectAll('.line-chart-dot')
          .data(props.data)
          .enter()
          .append('circle')
          .attr('class', 'line-chart-dot primary')
          .attr('fill', '#2e4faa')
          .attr('stroke', '#2e4faa')
          .attr('cx', lineGraph.x())
          .attr('cy', lineGraph.y())
          .attr('r', 2)

        // Animate points on page load
        pointsGraph
          .style('opacity', 0)
          .transition()
          .duration(250)
          .ease(easeLinear)
          .delay(1000)
          .style('opacity', 1)

        // Add user interaction
        pointsGraph
          .on('mouseover', function (e, v) {
            tooltip.offset([-10, 0]).direction('n').show(e, v)

            // Animate circle radius
            select(this).transition().duration(250).attr('r', 3)
          })
          .on('mouseout', function () {
            tooltip.hide()
            // Animate circle radius
            select(this).transition().duration(250).attr('r', 2)
          })

        // Change tooltip direction of first point
        select(pointsGraph._groups[0][0])
          .on('mouseover', function (e, v) {
            tooltip.offset([0, 10]).direction('e').show(e, v)

            // Animate circle radius
            select(this).transition().duration(250).attr('r', 3)
          })
          .on('mouseout', function () {
            tooltip.hide()

            // Animate circle radius
            select(this).transition().duration(250).attr('r', 2)
          })

        // Change tooltip direction of last point
        select(pointsGraph._groups[0][pointsGraph.size() - 1])
          .on('mouseover', function (e, v) {
            tooltip.offset([0, -10]).direction('w').show(e, v)

            // Animate circle radius
            select(this).transition().duration(250).attr('r', 3)
          })
          .on('mouseout', function () {
            tooltip.hide()

            // Animate circle radius
            select(this).transition().duration(250).attr('r', 2)
          })
      })
    })

    return { svgRef }
  }
}
</script>

<style lang="scss" scoped>
:deep(svg) {
  display: block;
  fill: none;
  stroke: none;
  width: 100%;
  height: 100%;
  overflow: visible !important;

  .line-chart {
    stroke-width: 1.5px;
    stroke: black;

    &.primary {
      stroke: $primary;
    }

    &.warning {
      stroke: $warning;
    }

    &.success {
      stroke: $success;
    }

    &.info {
      stroke: $info;
    }
  }

  .line-chart-dot {
    @apply cursor-pointer;
  }
}
</style>
