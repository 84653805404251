<template>
  <div ref="resizeRef" class="pt-8 overflow-hidden">
    <svg ref="svgRef" :id="id">
      <g>
        <g class="x-axis" v-if="axis === 'x' || axis === 'xy'" />
        <g class="y-axis" v-if="axis === 'y' || axis === 'xy'" />
      </g>
    </svg>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from 'vue'
import {
  select,
  line,
  scaleLinear,
  scalePoint,
  max,
  min,
  curveCardinal,
  axisBottom,
  axisLeft,
  easeLinear
} from 'd3'
import { tip } from 'd3-v6-tip'
import useResizeObserver from '@/tools/resizeObserver'

import { FORMAT } from '@/tools'

export default {
  name: 'LineChart',
  props: ['id', 'data', 'axis', 'xLabel', 'yLabel', 'xData', 'format'],
  setup(props) {
    const svgRef = ref(null)
    const { resizeRef, resizeState } = useResizeObserver()

    onMounted(() => {
      watchEffect(() => {
        let { width, height } = resizeState.dimensions
        let maxw = 0
        let maxh = 0
        const margin = { top: 20, right: 20, bottom: 40, left: 40 }

        width = width - margin.left - margin.right
        height = 350 - margin.top - margin.bottom

        const svg = select(svgRef.value)
          .style('background-color', 'white')
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
          .attr(
            'transform',
            'translate(' + margin.left + ',' + margin.top + ')'
          )

        svg
          .select('.y-axis')
          .selectAll('text')
          .each(function () {
            if (this.getBBox().width > maxw) maxw = this.getBBox().width
          })

        svg
          .select('.x-axis')
          .selectAll('text')
          .each(function () {
            if (this.getBBox().width > maxh) maxh = this.getBBox().width
          })

        const minData = min(props.data, v => v.value)
        const maxData = max(props.data, v => v.value)

        const xScale = scalePoint()
          .domain(['', ...props.data.map(v => v.name), ' '])
          .range([0, width - maxw])

        const yScale = scaleLinear()
          .domain([minData - minData * 0.1, maxData + maxData * 0.1])
          .range([height, 0])

        const xAxis = axisBottom(xScale)
        const yAxis = axisLeft(yScale).ticks(5)

        // Tooltip
        const tooltip = tip()
          .attr('class', 'd3-tip')
          .offset([-10, 0])
          .html((e, v) => {
            if (props.format === 'number') {
              return FORMAT.toNumber(v.value)
            } else {
              return FORMAT.toCurrency(v.value)
            }
          })

        svg.call(tooltip)

        // Refresh Graph
        svg.selectAll('circle').remove()
        svg.selectAll('.line-chart').remove()
        svg.selectAll('.x-label').remove()
        svg.selectAll('.y-label').remove()

        // ------------------------------

        const lineGraph = line()
          .curve(curveCardinal)
          .x(v => xScale(v.name))
          .y(v => yScale(v.value))

        const lineData = svg
          .append('g')
          .selectAll('.line-chart')
          .data([props.data])
          .join('path')
          .attr('class', 'line-chart primary')
          .style('fill', 'none')
          .attr('stroke', '#2e4faa')
          .attr('stroke-width', '1.5px')
          .attr('d', lineGraph)

        // Animate points on page load
        const totalLength = lineData.node().getTotalLength()
        const transitionPath = lineData
          .transition()
          .ease(easeLinear)
          .duration(1000)

        // set animation
        lineData
          .attr('stroke-dashoffset', totalLength)
          .attr('stroke-dasharray', totalLength)
          .transition(transitionPath)
          .attr('stroke-dashoffset', 0)

        // Points Graph
        const pointsGraph = svg
          .append('g')
          .selectAll('.line-chart-dot')
          .data(props.data)
          .enter()
          .append('circle')
          .attr('class', 'line-chart-dot primary')
          .style('fill', '#2e4faa')
          .attr('cx', lineGraph.x())
          .attr('cy', lineGraph.y())
          .attr('r', 2)

        // Animate points on page load
        pointsGraph
          .style('opacity', 0)
          .transition()
          .duration(250)
          .ease(easeLinear)
          .delay(1000)
          .style('opacity', 1)

        // Add user interaction
        pointsGraph
          .on('mouseover', function (e, v) {
            tooltip.offset([-10, 0]).direction('n').show(e, v)

            // Animate circle radius
            select(this).transition().duration(250).attr('r', 3)
          })
          .on('mouseout', function () {
            tooltip.hide()
            // Animate circle radius
            select(this).transition().duration(250).attr('r', 2)
          })

        // Change tooltip direction of first point
        select(pointsGraph._groups[0][0])
          .on('mouseover', function (e, v) {
            tooltip.offset([0, 10]).direction('e').show(e, v)

            // Animate circle radius
            select(this).transition().duration(250).attr('r', 3)
          })
          .on('mouseout', function () {
            tooltip.hide()

            // Animate circle radius
            select(this).transition().duration(250).attr('r', 2)
          })

        // Change tooltip direction of last point
        select(pointsGraph._groups[0][pointsGraph.size() - 1])
          .on('mouseover', function (e, v) {
            tooltip.offset([0, -10]).direction('w').show(e, v)

            // Animate circle radius
            select(this).transition().duration(250).attr('r', 3)
          })
          .on('mouseout', function () {
            tooltip.hide()

            // Animate circle radius
            select(this).transition().duration(250).attr('r', 2)
          })

        // set axis
        svg
          .select('.x-axis')
          .attr('class', 'x-axis')
          .attr('transform', 'translate(0,' + height + ')')
          .call(xAxis.tickSize(-height))
          .selectAll('text')
          .style('text-anchor', 'start')
          .attr('dx', '10px')
          .attr('dy', '-2px')
          .attr('transform', 'rotate(65)')

        svg
          .select('.x-axis')
          .append('text')
          .attr('class', 'x-label')
          .attr('x', (width - margin.left) / 2)
          .attr('y', maxh + 10)
          .text(props.xLabel)

        svg
          .select('.y-axis')
          .call(yAxis.tickSize(-(width - maxw)))
          .attr('class', 'y-axis')
          .append('text')
          .attr('class', 'y-label')
          .attr('transform', 'rotate(-90)')
          .attr('x', -(height - margin.top) / 2)
          .attr('y', -maxw - 10)
          .attr('dy', -16)
          .style('text-anchor', 'end')
          .text(props.yLabel)

        svg
          .attr('height', height + margin.top + margin.bottom + maxh)
          .attr(
            'viewBox',
            `-${maxw + margin.left} 0 ${
              width + margin.left + margin.right + margin.top + margin.bottom
            } ${
              height + margin.top + margin.bottom + margin.left + margin.right
            }`
          )
      })
    })

    return { svgRef, resizeRef }
  }
}
</script>

<style lang="scss" scoped>
:deep(svg) {
  display: block;
  fill: none;
  stroke: none;
  width: 100%;
  height: 100%;
  overflow: visible !important;

  .line-chart,
  .line-chart-dot {
    stroke-width: 1.5px;
    stroke: black;

    &.primary {
      fill: $primary;
      stroke: $primary;
    }

    &.warning {
      fill: $warning;
      stroke: $warning;
    }

    &.success {
      fill: $success;
      stroke: $success;
    }

    &.info {
      fill: $info;
      stroke: $info;
    }
  }

  .y-axis,
  .x-axis {
    line,
    path {
      color: $grey-6;
    }

    line {
      stroke: lightgrey;
      opacity: 0.7;
    }
  }

  .y-label,
  .x-label {
    @apply text-sm font-semibold;
    fill: black;
  }
}
</style>
