<template>
  <q-chip :class="tagClass" :removable="removable" dark>
    <span class="ellipsis">{{ text }}</span>
    <Tooltip :text="text" v-if="tooltip" />
  </q-chip>
</template>

<script>
import Tooltip from '@/components/Tooltip'

export default {
  name: 'Tag',
  props: {
    variant: {
      type: String,
      default: '',
      validator: val => {
        if (val) {
          return [
            'default',
            'primary',
            'secondary',
            'success',
            'warning',
            'info',
            'danger'
          ].includes(val)
        }
        return true
      }
    },
    text: {
      type: [String, Number],
      default: ''
    },
    removable: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Boolean,
      default: false
    }
  },
  components: { Tooltip },
  setup(props) {
    const tagClass = {
      'bg-white text-primary font-semibold': !props.variant,
      'bg-gray text-black': props.variant === 'default',
      'bg-primary': props.variant === 'primary',
      'bg-secondary': props.variant === 'secondary',
      'bg-success': props.variant === 'success',
      'bg-warning': props.variant === 'warning',
      'bg-info': props.variant === 'info',
      'bg-danger': props.variant === 'danger'
    }

    return { tagClass }
  }
}
</script>
