<template>
  <div ref="resizeRef" class="pt-8 pr-8 overflow-hidden">
    <div class="legendContainer">
      <div
        class="legendItem"
        :class="!legend1 ? 'text-gray' : ''"
        @click="onToggleLegend"
      >
        <div
          class="legendBox primary"
          :class="!legend1 ? 'bg-gray' : 'bg-primary'"
        />
        Selected Date
      </div>
      <div
        class="legendItem"
        :class="!legend2 ? 'text-gray' : ''"
        @click="onToggleLegend2"
      >
        <div
          class="legendBox success"
          :class="!legend2 ? 'bg-gray' : 'bg-success'"
        />
        {{ legend }} Ago
      </div>
    </div>
    <svg ref="svgRef" :id="id">
      <g>
        <g class="x-axis" v-if="axis === 'x' || axis === 'xy'" />
        <g class="y-axis" v-if="axis === 'y' || axis === 'xy'" />
      </g>
    </svg>
  </div>
</template>

<script>
import { ref, computed, watchEffect, onMounted } from 'vue'
import {
  select,
  scaleBand,
  scaleLinear,
  scaleOrdinal,
  axisBottom,
  axisLeft,
  max,
  easeCubic
} from 'd3'
import { tip } from 'd3-v6-tip'
import useResizeObserver from '@/tools/resizeObserver'

import { FORMAT } from '@/tools'

export default {
  name: 'HorizontalBarChart',
  props: [
    'id',
    'type',
    'data',
    'limit',
    'axis',
    'xLabel',
    'yLabel',
    'sortType',
    'legendType'
  ],
  setup(props) {
    const { resizeRef, resizeState } = useResizeObserver()
    const svgRef = ref(null)
    const legend = ref('')
    const legend1 = ref(true)
    const legend2 = ref(true)

    const onToggleLegend = () => {
      legend1.value = !legend1.value
    }

    const onToggleLegend2 = () => {
      legend2.value = !legend2.value
    }

    const graphData = computed(() => {
      if (props.limit) {
        return props.data.slice(0, props.limit)
      } else {
        return props.data
      }
    })

    onMounted(() => {
      legend.value = props.legendType

      watchEffect(() => {
        let { width, height } = resizeState.dimensions
        let maxw = 0
        let maxh = 0
        const margin = { top: 20, right: 20, bottom: 40, left: 40 }

        width = width - margin.left - margin.right
        height = 650 - margin.top - margin.bottom

        // List of subgroups
        const subgroups =
          legend1.value && legend2.value
            ? ['value', 'value2']
            : legend1.value
            ? ['value', '']
            : legend2.value
            ? ['', 'value2']
            : []

        // List of groups
        const groups = graphData.value.map(data => data.name)

        const maxData = max(graphData.value, v => {
          return Math.max(v.value)
        })

        const maxData2 = max(graphData.value, v => {
          return Math.max(v.value2)
        })

        const getMaxData = () => {
          if (maxData < maxData2) {
            return maxData2
          } else {
            return maxData
          }
        }

        const svg = select(svgRef.value)
          .style('background-color', 'white')
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
          .attr(
            'transform',
            'translate(' + margin.left + ',' + margin.top + ')'
          )

        svg
          .select('.y-axis')
          .selectAll('text')
          .each(function () {
            if (this.getBBox().width > maxw) maxw = this.getBBox().width
          })

        svg
          .select('.x-axis')
          .selectAll('text')
          .each(function () {
            if (this.getBBox().width > maxh) maxh = this.getBBox().width
          })

        // Add Y axis
        const yScale = scaleBand()
          .domain(groups)
          .rangeRound([0, height])
          .padding(0.3)

        // Add X axis
        const xScale = scaleLinear()
          .domain([0, getMaxData()])
          .rangeRound([0, width - maxw])

        // Another scale for subgroup position
        const ySubgroup = scaleBand()
          .domain(subgroups)
          .range([0, yScale.bandwidth()])
          .padding([0.05])

        // color palette = one color per subgroup
        const color = scaleOrdinal()
          .domain(subgroups)
          .range(['#2e4faa', '#54b275'])

        const xAxis = axisBottom(xScale)
        const yAxis = axisLeft(yScale)

        const tooltip = tip()
          .attr('class', 'd3-tip')
          .offset([-10, 0])
          .html((e, v) => {
            if (props.sortType === 'sales') {
              return FORMAT.toCurrency(v.value)
            } else {
              return v.value
            }
          })

        svg.call(tooltip)

        // Refresh Graph
        svg.selectAll('rect').remove()
        svg.selectAll('.x-label').remove()
        svg.selectAll('.y-label').remove()

        const barGraph = svg
          .append('g')
          .selectAll('g')
          .data(props.data)
          .join('g')
          .attr('transform', v => `translate(0,${yScale(v.name)})`)
          .selectAll('rect')
          .data(v => subgroups.map(key => ({ key: key, value: v[key] })))
          .join('rect')
          .attr('y', v => ySubgroup(v.key))
          .attr('x', () => xScale(0))
          .attr('height', ySubgroup.bandwidth())
          .attr('width', () => xScale(0))
          .attr('fill', v => color(v.key))
          .on('mouseover', tooltip.show)
          .on('mouseout', tooltip.hide)

        // set animation
        barGraph
          .transition()
          .ease(easeCubic)
          .duration(800)
          .attr('width', v => xScale(v.value))
          .delay((v, i) => i * 5)

        // set axis
        svg
          .select('.x-axis')
          .attr('class', 'x-axis')
          .attr('transform', 'translate(0,' + height + ')')
          .call(xAxis.tickSize(-height))
          .selectAll('text')
          .style('text-anchor', 'start')
          .attr('dx', '10px')
          .attr('dy', '-2px')
          .attr('transform', 'rotate(65)')

        svg
          .select('.x-axis')
          .append('text')
          .attr('class', 'x-label')
          .attr('x', (width - margin.left) / 2)
          .attr('y', maxh + 10)
          .text(props.xLabel)

        svg
          .select('.y-axis')
          .call(yAxis)
          .attr('class', 'y-axis')
          .append('text')
          .attr('class', 'y-label')
          .attr('transform', 'rotate(-90)')
          .attr('x', -(height - margin.top) / 2)
          .attr('y', -maxw - 10)
          .attr('dy', -16)
          .style('text-anchor', 'end')
          .text(props.yLabel)

        svg
          .attr('height', height + margin.bottom + maxh)
          .attr(
            'viewBox',
            `-${maxw + margin.left} 0 ${
              width + margin.left + margin.right + margin.top + margin.bottom
            } ${
              height + margin.top + margin.bottom + margin.left + margin.right
            }`
          )
      })
    })

    return {
      svgRef,
      resizeRef,
      legend,
      legend1,
      legend2,
      onToggleLegend,
      onToggleLegend2
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(svg) {
  display: block;
  fill: none;
  stroke: none;
  width: 100%;
  height: 100%;
  overflow: visible !important;

  .bar {
    fill: $primary;

    &.primary {
      fill: $primary;
    }

    &.warning {
      fill: $warning;
    }

    &.success {
      fill: $success;
    }

    &.info {
      fill: $info;
    }
  }

  .y-axis,
  .x-axis {
    line,
    path {
      color: $grey-6;
    }

    line {
      stroke: lightgrey;
      opacity: 0.7;
    }
  }

  .y-label,
  .x-label {
    @apply text-sm font-semibold;
    fill: black;
  }
}

.legendContainer {
  @apply mb-4;
  @apply flex items-center justify-center gap-8;

  .legendItem {
    @apply flex items-center justify-center gap-2;
    @apply cursor-pointer;

    &:hover {
      @apply font-semibold text-black;

      .legendBox {
        &.primary {
          @apply bg-primary;
        }

        &.success {
          @apply bg-success;
        }
      }
    }

    .legendBox {
      @apply w-2 h-2;
    }
  }
}
</style>
